@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
/* RSCSS */

html,
body {
  min-height: 100%;
}

body {
  background: -webkit-gradient(linear, left top, left bottom, from(#0f4c81), to(#094070)) no-repeat;
  background: linear-gradient(#0f4c81, #094070) no-repeat;
  color: white;
  font-family: "Roboto Mono", monospace;
  text-align: center;
}

h1,
h2,
h3,
h4 {
  display: inline-block;
  position: relative;
  text-align: center;
}

h1:after {
  border-bottom: 1px dotted #85cdd9;
  bottom: -10px;
  content: "";
  display: inline-block;
  height: 2px;
  position: absolute;
  left: -20px;
  right: -20px;
}

.section-wrap {
  margin: 20px;
}

.section-wrap p {
  line-height: 1.8;
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
}

.tooltip {
  color: #85cdd9;
  position: relative;
}

.tooltip:after {
  border-bottom: 2px dotted #85cdd9;
  bottom: -2px;
  content: "";
  display: inline-block;
  height: 2px;
  position: absolute;
  left: -2px;
  right: -2px;
}

.tooltip:hover {
  cursor: pointer;
}

.toolbar-wrap select {
  margin: 18px 24px;
}

